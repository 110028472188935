import React, { Component } from "react";
import classes from "./Footer.module.css";

class Footer extends Component {
    render() {
        return (
            <div>
                <footer className={["mt-auto page-footer font-small mdb-color lighten-3", classes.footer].join(" ")}>

                    <div className={["text-center py-3", classes.copyright].join(" ")}>© 2021 Copyright: Robert Hsieh</div>

                </footer>
            </div>
        );
    }

}

export default Footer;